import React, { useState } from 'react'
import {
  Link as RouterLink
} from 'react-router-dom'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { format } from 'date-fns'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'
import DateFnsUtils from '@date-io/date-fns'
import {
  ArrayHelpers,
  FastField as Field,
  FieldArray,
  Formik,
  FormikErrors,
} from 'formik'
import { compact } from 'lodash'

import * as Yup from 'yup'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import CalendarIcon from '@material-ui/icons/EventNoteTwoTone'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Drawer from '@material-ui/core/Drawer'

import {
  CreateGameInput,
  Course,
  ModelSortDirection,
  Player,
} from './API'
import { listCourses, listPlayers } from './graphql/queries'
import { createGame } from './graphql/mutations'

const useStyles = makeStyles((theme: Theme) =>
                             createStyles({
                               avatar: {
                                 width: theme.spacing(10),
                                 height: theme.spacing(10),
                               },
                               header: {
                                 position: 'fixed',
                                 top: 0,
                                 width: '100%',
                               },
                               formControl: {
                                 marginBottom: 20,
                                 minWidth: 300,
                               },
                             }),
                            )

const playerSchema = Yup.object({
  id: Yup.string().required('You must select a Player'),
  handicap: Yup.number().when(
    'id',
    (id: string, schema: Yup.StringSchema) => (
      id && schema.required('You must add a handicap for Player')
    )
  ),
  stablefordPoints: Yup.number().when(
    'id',
    (id: string, schema: Yup.NumberSchema) => (
      id && schema.required('You must add stableford points for Player')
    )
  ),
}).required()

type PlayerSchema = Yup.InferType<typeof playerSchema>

const formSchema = Yup.object({
  competitionId: Yup.string().required(),
  date: Yup.date().required(),
  courseId: Yup.string().required('You must select a Course'),
  coursePar: Yup.number().when(
    'courseId',
    (courseId: string, schema: Yup.NumberSchema) => (
      courseId && schema.required('You must input a Course Par')
    )
  ),
  players: Yup.array().of(playerSchema).required('You must select at least one Player'),
}).required()

type FormSchema = Yup.InferType<typeof formSchema>

export default function NewGame() {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState()
  const [dialogVisible, setDialogVisible] = useState(false)

  const coursesQuery = gql`${listCourses}`
  const {
    loading: coursesLoading,
    // TODO
    // error: coursesError,
    data: coursesData,
  } = useQuery(coursesQuery, {
    variables: {
      limit: 999,
      sortDirection: ModelSortDirection.ASC,
    },
    fetchPolicy: 'cache-and-network',
  })

  const playersQuery = gql`${listPlayers}`
  const {
    loading: playersLoading,
    // TODO
    // error: playersError,
    data: playersData,
  } = useQuery(playersQuery, {
    variables: {
      limit: 999,
      sortDirection: ModelSortDirection.ASC,
    },
    fetchPolicy: 'cache-and-network',
  })

  const createGameMutation = gql`${createGame}`
  const [createGameFn] = useMutation(createGameMutation)

  const loading = coursesLoading || playersLoading

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={300}
      >
        <CircularProgress />
      </Box>
    )
  }

  // TODO handle data errors
  // TODO cannot add already selected players
  // TODO set handicap when adding player

  const courses: Course[] = coursesData?.listCourses?.items
  const players: Player[] = playersData?.listPlayers?.items

  const initialValues = {
    // TODO dynamic competitionId
    competitionId: '1ab9dc35-23c3-4aec-be95-485772ef724a',
    courseId: '',
    coursePar: undefined,
    date: new Date(),
    players: [],
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={async (values, actions) => {
        console.log('Submitting...', { values })

        if (!formSchema.isValid(values)) {
          console.error('Invalid Submission', {
            values,
          })
          return
        }

        const games = mapFormToGames(values as FormSchema)

        // TODO handle error
        if (!games?.length) {
          console.error('No games to save', {
            values,
          })
          return
        }

        console.log('Processing Games...', { games })

        for (const game of games) {
        // TODO handle error
          console.log('Processing Game...', { game })

          try {
            // TODO error handling
            await createGameFn({
              variables: {
                input: game,
              }
            })

            setDialogVisible(true)
          } catch (err) {
            console.error('Error creating game', { err })
            throw err
          }
        }

        actions.setSubmitting(false)
      }}
    >
      {(props) => {
        const submitted = props.submitCount > 0

        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box paddingBottom={4}>
              <Box
                bgcolor="primary.main"
                className={classes.header}
                color="white"
                zIndex="appBar"
              >
                <Box p={2.5}>
                  <Typography variant="h5" component="h1">
                    New Game
                  </Typography>
                  <Typography
                    style={{
                      fontSize: '90%',
                      fontWeight: 600,
                      letterSpacing: 2,
                      textTransform: 'uppercase',
                    }}
                  >
                    {format(new Date(), 'dd MMM yyyy')}
                  </Typography>
                </Box>
                <Box
                  bgcolor="#f1f1f1"
                  display="flex"
                  alignItems="center"
                  pl={1}
                >
                  <CalendarIcon color="action" fontSize="small" />
                  <Typography
                    color="textPrimary"
                    style={{ marginLeft: 5 }}
                    variant="overline"
                  >
                    Final: 3rd - 5th Dec 2021
                  </Typography>
                </Box>
              </Box>
              <Box pt={17} pb={4}>
                <Container maxWidth="sm">
                  <Box>
                    <Box>
                      <Field
                        as={KeyboardDatePicker}
                        className={classes.formControl}
                        error={submitted && !!props.errors.date}
                        format="E dd MMM yyyy"
                        id="date-picker-dialog"
                        inputVariant="outlined"
                        label="Date"
                        margin="normal"
                        name="date"
                        onChange={(date: string) => {
                          props.setFieldValue('date', new Date(date))
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Box>

                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                    >
                      <InputLabel id="courseId" variant="outlined">Select Course</InputLabel>
                      <Field
                        as={Select}
                        disabled={coursesLoading}
                        error={submitted && !!props.errors.courseId}
                        name="courseId"
                        native
                        onChange={(event: any) => {
                          props.handleChange(event)

                          const courseId = event.target.value
                          const courseDoc = courses && courses.find(courseItem => courseItem.id === courseId)

                          if (courseDoc) props.setFieldValue('coursePar', courseDoc.par)
                        }}
                      >
                        <option aria-label="none"></option>
                        {courses && courses.sort(sortByName).map((course: Course) => (
                        <option
                          key={course.id}
                          value={course.id}
                        >
                          {course.name}
                        </option>
                        ))}
                      </Field>
                    </FormControl>

                    <Field
                      as={TextField}
                      className={classes.formControl}
                      error={submitted && !!props.errors.coursePar}
                      helperText={submitted && props.errors.coursePar}
                      label="Course Par"
                      name="coursePar"
                      type="number"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />

                    <Box>
                      <FieldArray name="players">
                        {(arrayHelpers: ArrayHelpers) => {

                          function addPlayer(player: Player) {
                            arrayHelpers.push({ id: player.id })
                            setAnchorEl(null as any)
                          }

                          return (
                            <Box>
                              {props.values.players.map((player: PlayerSchema, index) => {
                                return (
                                  <PlayerForm
                                    key={index}
                                    arrayHelpers={arrayHelpers}
                                    formErrors={props.errors}
                                    index={index}
                                    loading={playersLoading}
                                    player={player}
                                    players={players}
                                    submitted={submitted}
                                  />
                                )
                              })}
                              <Box marginBottom={2}>
                                <Button
                                  aria-controls="player-menu"
                                  aria-haspopup="true"
                                  color="primary"
                                  onClick={(event: any) => setAnchorEl(event.currentTarget as any)}
                                  variant="outlined"
                                >
                                  Add Player
                                </Button>
                                <Menu
                                  id="player-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={() => setAnchorEl(null as any)}
                                >
                                  {players && players.sort(sortByName).map((player: Player) => (
                                    <MenuItem key={player.id} onClick={() => addPlayer(player)}>
                                      <Box marginRight={1}>
                                        <Avatar
                                          alt={player.name}
                                          src={player.avatar}
                                        />
                                      </Box>
                                      {player.name}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </Box>
                            </Box>
                          )
                        }}
                      </FieldArray>
                    </Box>

                    <Button
                      color="primary"
                      disableElevation
                      disabled={!props.dirty || props.isSubmitting}
                      onClick={() => {
                        if (props.isSubmitting) return

                        props.submitForm()
                      }}
                      size="large"
                      variant="contained"
                    >
                      {props.isSubmitting ? 'Submitting...' : 'Submit'}
                    </Button>

                    {/* <pre> */}
                    {/*     {JSON.stringify(props, null, 1)} */}
                    {/* </pre> */}

                  </Box>
                </Container>
              </Box>
            </Box>

            <Drawer anchor="bottom" open={dialogVisible} onClose={() => setDialogVisible(false)}>
              <GameSummary values={props.values} courses={courses} players={players}/>
            </Drawer>
          </MuiPickersUtilsProvider>
        )
      }}
    </Formik>
  )
}

interface PlayerFormProps {
  arrayHelpers: ArrayHelpers
  formErrors: any // NOTE cannot assign FormikErrors here ¯\_(ツ)_/¯
  index: number
  loading: boolean
  players: Player[]
  player: PlayerSchema
  submitted: boolean
}

function PlayerForm(props: PlayerFormProps) {
  const {
    arrayHelpers,
    formErrors,
    index,
    loading,
    player,
    players,
    submitted,
  } = props

  const classes = useStyles()

  if (!player) return null

  const playerDoc = players && players.find(playerItem => playerItem.id === player.id)
  const errors: FormikErrors<PlayerSchema> = formErrors?.players?.[index] || {}

  return (
    <Box
      key={player.id}
      bgcolor="#f5f5f5"
      borderRadius={4}
      marginBottom={2}
      padding={2}
    >
      {playerDoc ? (
        <Box display="flex" flexDirection="row">
          <Box
            justifyContent="space-between"
            flex={1}
            flexDirection="row"
            display="flex"
          >
            <Box>
              <Typography variant="h6">
                {playerDoc.name}
              </Typography>
              <Box>
                <Field
                  as={TextField}
                  label="Handicap"
                  name={`players.${index}.handicap`}
                  error={submitted && !!errors.handicap}
                  margin="dense"
                  size="small"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box>
                <Field
                  as={TextField}
                  label="Stableford Points"
                  name={`players.${index}.stablefordPoints`}
                  error={submitted && !!errors.stablefordPoints}
                  margin="dense"
                  size="small"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="flex-end"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Avatar
                alt={playerDoc.name}
                className={classes.avatar}
                src={playerDoc.avatar}
              />
              <Box>
                <Button
                  onClick={() => {
                    if (window.confirm('Remove player?')) {
                      arrayHelpers.remove(index)
                    }
                  }}
                  size="small"
                >
                  Remove
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <FormControl
          disabled={loading}
          fullWidth
          margin="none"
          variant="outlined"
        >
          <InputLabel id="id">
            Select Player
          </InputLabel>
          <Field
            as={Select}
            autoWidth
            label="Select Player"
            name={`players.${index}.id`}
            native
          >
            <option aria-label="None" value="" />
            {players && players.sort(sortByName).map((player: Player) => (
            <option
              key={player.id}
              value={player.id}
            >
              {player.name}
            </option>
            ))}
          </Field>
        </FormControl>
      )}
    </Box>
  )
}

interface GameSummaryProps {
  values: FormSchema
  courses: Course[]
  players: Player[]
}

function GameSummary(props: GameSummaryProps) {
  const { values, courses, players } = props
  const courseDoc = courses && courses.find(courseItem => courseItem.id === values.courseId)
  const courseName = courseDoc && courseDoc.name
  const dateFormatted = format(new Date(values.date), 'dd MMM yyyy')

  return (
    <Box padding={2}>
      <Typography variant="h6">
        Game Saved!
      </Typography>
      <Typography gutterBottom variant="subtitle2">
        Copy & Paste game to Whatsapp scoreboard group:
      </Typography>
      <Box
        bgcolor="#f1f1f1"
        border="1px dashed #ccc"
        borderRadius={4}
        marginBottom={2}
        padding={2}
      >
        <Typography variant="body1">
          <span role="img" aria-label="golf icon">⛳</span> {courseName} - {dateFormatted}
        </Typography>

        <br />

        {values.players.map((player: PlayerSchema) => {
          const playerDoc = players && players.find(playerItem => playerItem.id === player.id)

          if (!playerDoc) return null

          return (
            <Typography key={player.id} variant="body1">
              {playerDoc.name} - {player.stablefordPoints} points ({player.handicap})
            </Typography>
          )
        })}
      </Box>
      <Link component={RouterLink} to="/">
        Go to Scoreboard
      </Link>
    </Box>
  )
}

function mapFormToGames(values: FormSchema): CreateGameInput[] | null {
  const {
    competitionId,
    date,
    courseId,
    coursePar,
    players,
  } = values

  if (!courseId || !coursePar) return null

  const formattedDate = format(date, 'yyyy/MM/dd')

  const games = players.map(player => {
    if (!player.id || !player.handicap || !player.stablefordPoints) {
      return null
    }

    return {
      competitionId,
      courseId,
      coursePar,
      date: formattedDate,
      playerId: player.id,
      playerHandicap: player.handicap,
      playerStablefordPoints: player.stablefordPoints
    }
  })

  return compact(games)
}

function sortByName(a: any, b: any): number {
  return (a.name > b.name) ? 1 : -1
}
