/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCompetitionInput = {
  id?: string | null,
  name: string,
  _version?: number | null,
};

export type ModelCompetitionConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelCompetitionConditionInput | null > | null,
  or?: Array< ModelCompetitionConditionInput | null > | null,
  not?: ModelCompetitionConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type Competition = {
  __typename: "Competition",
  id?: string,
  name?: string,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateCompetitionInput = {
  id: string,
  name?: string | null,
  _version?: number | null,
};

export type DeleteCompetitionInput = {
  id?: string | null,
  _version?: number | null,
};

export type CreateCourseInput = {
  id?: string | null,
  name: string,
  par: number,
  rating: CourseRating,
  _version?: number | null,
};

export enum CourseRating {
  EASY = "EASY",
  MEDIUM = "MEDIUM",
  HARD = "HARD",
}


export type ModelCourseConditionInput = {
  name?: ModelStringInput | null,
  par?: ModelIntInput | null,
  rating?: ModelCourseRatingInput | null,
  and?: Array< ModelCourseConditionInput | null > | null,
  or?: Array< ModelCourseConditionInput | null > | null,
  not?: ModelCourseConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelCourseRatingInput = {
  eq?: CourseRating | null,
  ne?: CourseRating | null,
};

export type Course = {
  __typename: "Course",
  id?: string,
  name?: string,
  par?: number,
  rating?: CourseRating,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateCourseInput = {
  id: string,
  name?: string | null,
  par?: number | null,
  rating?: CourseRating | null,
  _version?: number | null,
};

export type DeleteCourseInput = {
  id?: string | null,
  _version?: number | null,
};

export type CreatePlayerInput = {
  id?: string | null,
  avatar: string,
  handicap?: number | null,
  name: string,
  championships?: number | null,
  userId?: string | null,
  _version?: number | null,
};

export type ModelPlayerConditionInput = {
  avatar?: ModelStringInput | null,
  handicap?: ModelIntInput | null,
  name?: ModelStringInput | null,
  championships?: ModelIntInput | null,
  userId?: ModelStringInput | null,
  and?: Array< ModelPlayerConditionInput | null > | null,
  or?: Array< ModelPlayerConditionInput | null > | null,
  not?: ModelPlayerConditionInput | null,
};

export type Player = {
  __typename: "Player",
  id?: string,
  avatar?: string,
  handicap?: number | null,
  name?: string,
  championships?: number | null,
  userId?: string | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdatePlayerInput = {
  id: string,
  avatar?: string | null,
  handicap?: number | null,
  name?: string | null,
  championships?: number | null,
  userId?: string | null,
  _version?: number | null,
};

export type DeletePlayerInput = {
  id?: string | null,
  _version?: number | null,
};

export type CreateGameInput = {
  competitionId: string,
  courseId: string,
  coursePar: number,
  date: string,
  playerHandicap?: number | null,
  playerId: string,
  playerScore?: number | null,
  playerStablefordPoints: number,
  _version?: number | null,
};

export type ModelGameConditionInput = {
  coursePar?: ModelIntInput | null,
  playerHandicap?: ModelIntInput | null,
  playerScore?: ModelIntInput | null,
  playerStablefordPoints?: ModelIntInput | null,
  and?: Array< ModelGameConditionInput | null > | null,
  or?: Array< ModelGameConditionInput | null > | null,
  not?: ModelGameConditionInput | null,
};

export type Game = {
  __typename: "Game",
  competitionId?: string,
  courseId?: string,
  coursePar?: number,
  date?: string,
  playerHandicap?: number | null,
  playerId?: string,
  playerScore?: number | null,
  playerStablefordPoints?: number,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
  competition?: Competition,
  course?: Course,
  player?: Player,
};

export type UpdateGameInput = {
  competitionId: string,
  courseId: string,
  coursePar?: number | null,
  date: string,
  playerHandicap?: number | null,
  playerId: string,
  playerScore?: number | null,
  playerStablefordPoints?: number | null,
  _version?: number | null,
};

export type DeleteGameInput = {
  competitionId: string,
  date: string,
  courseId: string,
  playerId: string,
  _version?: number | null,
};

export type ModelCompetitionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelCompetitionFilterInput | null > | null,
  or?: Array< ModelCompetitionFilterInput | null > | null,
  not?: ModelCompetitionFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelCompetitionConnection = {
  __typename: "ModelCompetitionConnection",
  items?:  Array<Competition | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCourseFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  par?: ModelIntInput | null,
  rating?: ModelCourseRatingInput | null,
  and?: Array< ModelCourseFilterInput | null > | null,
  or?: Array< ModelCourseFilterInput | null > | null,
  not?: ModelCourseFilterInput | null,
};

export type ModelCourseConnection = {
  __typename: "ModelCourseConnection",
  items?:  Array<Course | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPlayerFilterInput = {
  id?: ModelIDInput | null,
  avatar?: ModelStringInput | null,
  handicap?: ModelIntInput | null,
  name?: ModelStringInput | null,
  championships?: ModelIntInput | null,
  userId?: ModelStringInput | null,
  and?: Array< ModelPlayerFilterInput | null > | null,
  or?: Array< ModelPlayerFilterInput | null > | null,
  not?: ModelPlayerFilterInput | null,
};

export type ModelPlayerConnection = {
  __typename: "ModelPlayerConnection",
  items?:  Array<Player | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelGamePrimaryCompositeKeyConditionInput = {
  eq?: ModelGamePrimaryCompositeKeyInput | null,
  le?: ModelGamePrimaryCompositeKeyInput | null,
  lt?: ModelGamePrimaryCompositeKeyInput | null,
  ge?: ModelGamePrimaryCompositeKeyInput | null,
  gt?: ModelGamePrimaryCompositeKeyInput | null,
  between?: Array< ModelGamePrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelGamePrimaryCompositeKeyInput | null,
};

export type ModelGamePrimaryCompositeKeyInput = {
  date?: string | null,
  courseId?: string | null,
  playerId?: string | null,
};

export type ModelGameFilterInput = {
  competitionId?: ModelIDInput | null,
  courseId?: ModelIDInput | null,
  coursePar?: ModelIntInput | null,
  date?: ModelStringInput | null,
  playerHandicap?: ModelIntInput | null,
  playerId?: ModelIDInput | null,
  playerScore?: ModelIntInput | null,
  playerStablefordPoints?: ModelIntInput | null,
  and?: Array< ModelGameFilterInput | null > | null,
  or?: Array< ModelGameFilterInput | null > | null,
  not?: ModelGameFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelGameConnection = {
  __typename: "ModelGameConnection",
  items?:  Array<Game | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type CreateCompetitionMutationVariables = {
  input?: CreateCompetitionInput,
  condition?: ModelCompetitionConditionInput | null,
};

export type CreateCompetitionMutation = {
  createCompetition?:  {
    __typename: "Competition",
    id: string,
    name: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCompetitionMutationVariables = {
  input?: UpdateCompetitionInput,
  condition?: ModelCompetitionConditionInput | null,
};

export type UpdateCompetitionMutation = {
  updateCompetition?:  {
    __typename: "Competition",
    id: string,
    name: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCompetitionMutationVariables = {
  input?: DeleteCompetitionInput,
  condition?: ModelCompetitionConditionInput | null,
};

export type DeleteCompetitionMutation = {
  deleteCompetition?:  {
    __typename: "Competition",
    id: string,
    name: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCourseMutationVariables = {
  input?: CreateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type CreateCourseMutation = {
  createCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    par: number,
    rating: CourseRating,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCourseMutationVariables = {
  input?: UpdateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type UpdateCourseMutation = {
  updateCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    par: number,
    rating: CourseRating,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCourseMutationVariables = {
  input?: DeleteCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type DeleteCourseMutation = {
  deleteCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    par: number,
    rating: CourseRating,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePlayerMutationVariables = {
  input?: CreatePlayerInput,
  condition?: ModelPlayerConditionInput | null,
};

export type CreatePlayerMutation = {
  createPlayer?:  {
    __typename: "Player",
    id: string,
    avatar: string,
    handicap?: number | null,
    name: string,
    championships?: number | null,
    userId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePlayerMutationVariables = {
  input?: UpdatePlayerInput,
  condition?: ModelPlayerConditionInput | null,
};

export type UpdatePlayerMutation = {
  updatePlayer?:  {
    __typename: "Player",
    id: string,
    avatar: string,
    handicap?: number | null,
    name: string,
    championships?: number | null,
    userId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePlayerMutationVariables = {
  input?: DeletePlayerInput,
  condition?: ModelPlayerConditionInput | null,
};

export type DeletePlayerMutation = {
  deletePlayer?:  {
    __typename: "Player",
    id: string,
    avatar: string,
    handicap?: number | null,
    name: string,
    championships?: number | null,
    userId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGameMutationVariables = {
  input?: CreateGameInput,
  condition?: ModelGameConditionInput | null,
};

export type CreateGameMutation = {
  createGame?:  {
    __typename: "Game",
    competitionId: string,
    courseId: string,
    coursePar: number,
    date: string,
    playerHandicap?: number | null,
    playerId: string,
    playerScore?: number | null,
    playerStablefordPoints: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    competition?:  {
      __typename: "Competition",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    course?:  {
      __typename: "Course",
      id: string,
      name: string,
      par: number,
      rating: CourseRating,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    player?:  {
      __typename: "Player",
      id: string,
      avatar: string,
      handicap?: number | null,
      name: string,
      championships?: number | null,
      userId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateGameMutationVariables = {
  input?: UpdateGameInput,
  condition?: ModelGameConditionInput | null,
};

export type UpdateGameMutation = {
  updateGame?:  {
    __typename: "Game",
    competitionId: string,
    courseId: string,
    coursePar: number,
    date: string,
    playerHandicap?: number | null,
    playerId: string,
    playerScore?: number | null,
    playerStablefordPoints: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    competition?:  {
      __typename: "Competition",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    course?:  {
      __typename: "Course",
      id: string,
      name: string,
      par: number,
      rating: CourseRating,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    player?:  {
      __typename: "Player",
      id: string,
      avatar: string,
      handicap?: number | null,
      name: string,
      championships?: number | null,
      userId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteGameMutationVariables = {
  input?: DeleteGameInput,
  condition?: ModelGameConditionInput | null,
};

export type DeleteGameMutation = {
  deleteGame?:  {
    __typename: "Game",
    competitionId: string,
    courseId: string,
    coursePar: number,
    date: string,
    playerHandicap?: number | null,
    playerId: string,
    playerScore?: number | null,
    playerStablefordPoints: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    competition?:  {
      __typename: "Competition",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    course?:  {
      __typename: "Course",
      id: string,
      name: string,
      par: number,
      rating: CourseRating,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    player?:  {
      __typename: "Player",
      id: string,
      avatar: string,
      handicap?: number | null,
      name: string,
      championships?: number | null,
      userId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type GetCompetitionQueryVariables = {
  id?: string,
};

export type GetCompetitionQuery = {
  getCompetition?:  {
    __typename: "Competition",
    id: string,
    name: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCompetitionsQueryVariables = {
  filter?: ModelCompetitionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompetitionsQuery = {
  listCompetitions?:  {
    __typename: "ModelCompetitionConnection",
    items?:  Array< {
      __typename: "Competition",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCompetitionsQueryVariables = {
  filter?: ModelCompetitionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCompetitionsQuery = {
  syncCompetitions?:  {
    __typename: "ModelCompetitionConnection",
    items?:  Array< {
      __typename: "Competition",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCourseQueryVariables = {
  id?: string,
};

export type GetCourseQuery = {
  getCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    par: number,
    rating: CourseRating,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCoursesQueryVariables = {
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCoursesQuery = {
  listCourses?:  {
    __typename: "ModelCourseConnection",
    items?:  Array< {
      __typename: "Course",
      id: string,
      name: string,
      par: number,
      rating: CourseRating,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCoursesQueryVariables = {
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCoursesQuery = {
  syncCourses?:  {
    __typename: "ModelCourseConnection",
    items?:  Array< {
      __typename: "Course",
      id: string,
      name: string,
      par: number,
      rating: CourseRating,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPlayerQueryVariables = {
  id?: string,
};

export type GetPlayerQuery = {
  getPlayer?:  {
    __typename: "Player",
    id: string,
    avatar: string,
    handicap?: number | null,
    name: string,
    championships?: number | null,
    userId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPlayersQueryVariables = {
  filter?: ModelPlayerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPlayersQuery = {
  listPlayers?:  {
    __typename: "ModelPlayerConnection",
    items?:  Array< {
      __typename: "Player",
      id: string,
      avatar: string,
      handicap?: number | null,
      name: string,
      championships?: number | null,
      userId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPlayersQueryVariables = {
  filter?: ModelPlayerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPlayersQuery = {
  syncPlayers?:  {
    __typename: "ModelPlayerConnection",
    items?:  Array< {
      __typename: "Player",
      id: string,
      avatar: string,
      handicap?: number | null,
      name: string,
      championships?: number | null,
      userId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetGameQueryVariables = {
  competitionId?: string,
  date?: string,
  courseId?: string,
  playerId?: string,
};

export type GetGameQuery = {
  getGame?:  {
    __typename: "Game",
    competitionId: string,
    courseId: string,
    coursePar: number,
    date: string,
    playerHandicap?: number | null,
    playerId: string,
    playerScore?: number | null,
    playerStablefordPoints: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    competition?:  {
      __typename: "Competition",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    course?:  {
      __typename: "Course",
      id: string,
      name: string,
      par: number,
      rating: CourseRating,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    player?:  {
      __typename: "Player",
      id: string,
      avatar: string,
      handicap?: number | null,
      name: string,
      championships?: number | null,
      userId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListGamesQueryVariables = {
  competitionId?: string | null,
  dateCourseIdPlayerId?: ModelGamePrimaryCompositeKeyConditionInput | null,
  filter?: ModelGameFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGamesQuery = {
  listGames?:  {
    __typename: "ModelGameConnection",
    items?:  Array< {
      __typename: "Game",
      competitionId: string,
      courseId: string,
      coursePar: number,
      date: string,
      playerHandicap?: number | null,
      playerId: string,
      playerScore?: number | null,
      playerStablefordPoints: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      competition?:  {
        __typename: "Competition",
        id: string,
        name: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      course?:  {
        __typename: "Course",
        id: string,
        name: string,
        par: number,
        rating: CourseRating,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      player?:  {
        __typename: "Player",
        id: string,
        avatar: string,
        handicap?: number | null,
        name: string,
        championships?: number | null,
        userId?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GamesByPlayerQueryVariables = {
  playerId?: string | null,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGameFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GamesByPlayerQuery = {
  gamesByPlayer?:  {
    __typename: "ModelGameConnection",
    items?:  Array< {
      __typename: "Game",
      competitionId: string,
      courseId: string,
      coursePar: number,
      date: string,
      playerHandicap?: number | null,
      playerId: string,
      playerScore?: number | null,
      playerStablefordPoints: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      competition?:  {
        __typename: "Competition",
        id: string,
        name: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      course?:  {
        __typename: "Course",
        id: string,
        name: string,
        par: number,
        rating: CourseRating,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      player?:  {
        __typename: "Player",
        id: string,
        avatar: string,
        handicap?: number | null,
        name: string,
        championships?: number | null,
        userId?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncGamesQueryVariables = {
  filter?: ModelGameFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncGamesQuery = {
  syncGames?:  {
    __typename: "ModelGameConnection",
    items?:  Array< {
      __typename: "Game",
      competitionId: string,
      courseId: string,
      coursePar: number,
      date: string,
      playerHandicap?: number | null,
      playerId: string,
      playerScore?: number | null,
      playerStablefordPoints: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      competition?:  {
        __typename: "Competition",
        id: string,
        name: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      course?:  {
        __typename: "Course",
        id: string,
        name: string,
        par: number,
        rating: CourseRating,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      player?:  {
        __typename: "Player",
        id: string,
        avatar: string,
        handicap?: number | null,
        name: string,
        championships?: number | null,
        userId?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateCompetitionSubscription = {
  onCreateCompetition?:  {
    __typename: "Competition",
    id: string,
    name: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCompetitionSubscription = {
  onUpdateCompetition?:  {
    __typename: "Competition",
    id: string,
    name: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCompetitionSubscription = {
  onDeleteCompetition?:  {
    __typename: "Competition",
    id: string,
    name: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCourseSubscription = {
  onCreateCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    par: number,
    rating: CourseRating,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCourseSubscription = {
  onUpdateCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    par: number,
    rating: CourseRating,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCourseSubscription = {
  onDeleteCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    par: number,
    rating: CourseRating,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePlayerSubscription = {
  onCreatePlayer?:  {
    __typename: "Player",
    id: string,
    avatar: string,
    handicap?: number | null,
    name: string,
    championships?: number | null,
    userId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePlayerSubscription = {
  onUpdatePlayer?:  {
    __typename: "Player",
    id: string,
    avatar: string,
    handicap?: number | null,
    name: string,
    championships?: number | null,
    userId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePlayerSubscription = {
  onDeletePlayer?:  {
    __typename: "Player",
    id: string,
    avatar: string,
    handicap?: number | null,
    name: string,
    championships?: number | null,
    userId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGameSubscription = {
  onCreateGame?:  {
    __typename: "Game",
    competitionId: string,
    courseId: string,
    coursePar: number,
    date: string,
    playerHandicap?: number | null,
    playerId: string,
    playerScore?: number | null,
    playerStablefordPoints: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    competition?:  {
      __typename: "Competition",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    course?:  {
      __typename: "Course",
      id: string,
      name: string,
      par: number,
      rating: CourseRating,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    player?:  {
      __typename: "Player",
      id: string,
      avatar: string,
      handicap?: number | null,
      name: string,
      championships?: number | null,
      userId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateGameSubscription = {
  onUpdateGame?:  {
    __typename: "Game",
    competitionId: string,
    courseId: string,
    coursePar: number,
    date: string,
    playerHandicap?: number | null,
    playerId: string,
    playerScore?: number | null,
    playerStablefordPoints: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    competition?:  {
      __typename: "Competition",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    course?:  {
      __typename: "Course",
      id: string,
      name: string,
      par: number,
      rating: CourseRating,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    player?:  {
      __typename: "Player",
      id: string,
      avatar: string,
      handicap?: number | null,
      name: string,
      championships?: number | null,
      userId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteGameSubscription = {
  onDeleteGame?:  {
    __typename: "Game",
    competitionId: string,
    courseId: string,
    coursePar: number,
    date: string,
    playerHandicap?: number | null,
    playerId: string,
    playerScore?: number | null,
    playerStablefordPoints: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    competition?:  {
      __typename: "Competition",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    course?:  {
      __typename: "Course",
      id: string,
      name: string,
      par: number,
      rating: CourseRating,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    player?:  {
      __typename: "Player",
      id: string,
      avatar: string,
      handicap?: number | null,
      name: string,
      championships?: number | null,
      userId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};
