/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:8622cafa-496a-4cfe-a146-c6a1c8438265",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_zw5rBeF2L",
    "aws_user_pools_web_client_id": "49acpqlfhbp5cpci6ovt6krl2e",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://dpn6qbafkrdgzi7vp27x22r36e.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-py6zrzxyx5c7vf2fzo2zkawrmm",
    "aws_user_files_s3_bucket": "mpga-imagesproduction-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-2",
    "aws_content_delivery_bucket": "thempga.com-production",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://d19vhxpou1cs90.cloudfront.net"
};


export default awsmobile;
