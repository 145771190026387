import {
  Game,
} from '../API'

import {
  compact,
  flow,
  isEmpty,
  map,
  meanBy,
  orderBy,
  take,
} from 'lodash/fp'

interface Input {
  games: Game[]
}

interface Output {
  handicap: number | null
}

export function johnEdisburyHandicapCalculator(input: Input): Output {
  const { games } = input

  if (isEmpty(games)) {
    return {
      handicap: null
    }
  }

  const average = flow(
    orderBy(['date'], ['desc']),
    take(20),
    map(mapScores),
    compact,
    orderBy(['stablefordDiff'], ['asc']),
    take(8),
    meanBy('overUnder'),
  )(games)

  const handicap: number = Math.round(average * 0.93)

  return {
    handicap,
  }
}

function mapScores(game: Game) {
  const {
    coursePar = 72,
    date,
    playerHandicap,
    playerId,
    playerStablefordPoints = 0,
  } = game

  if (!playerHandicap) {
    console.warn('Missing playerHandicap', {
      date,
      playerId,
    })
    return null
  }

  const stablefordDiff: number = 36 - playerStablefordPoints
  const score: number = coursePar + playerHandicap + stablefordDiff
  const overUnder: number = score - coursePar
  const courseName = game?.course?.name

  return {
    overUnder,
    stablefordDiff,
    playerStablefordPoints,
    course: courseName,
    date: game.date,
  }
}
