import React from 'react'
import ReactDOM from 'react-dom'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import Amplify, { Auth } from 'aws-amplify'
import { ApolloProvider } from '@apollo/react-hooks'
import LogRocket from 'logrocket'
import {
  BrowserRouter as Router,
} from 'react-router-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import awsconfig from './aws-exports'

Amplify.configure(awsconfig)

const clientConfig = {
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),

  },
  // NOTE This is needed to support mutations in app sync and apollo
  // See: https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/450
  // And: https://github.com/wolfeidau/appsync-apollo-links
  disableOffline: true,
}

// TODO type
const client = new AWSAppSyncClient(clientConfig as any)

LogRocket.init('ctfrvf/mpga')

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <App />
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
