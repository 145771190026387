import React from 'react'
import { withAuthenticator } from 'aws-amplify-react'
import LogRocket from 'logrocket'

import {
  Route,
  useHistory,
} from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Typography from '@material-ui/core/Typography'

import ScoreboardIcon from '@material-ui/icons/ListAltTwoTone'
import GameIcon from '@material-ui/icons/SportsGolfTwoTone'
import HandicapIcon from '@material-ui/icons/AccessibleForwardTwoTone';

import green from '@material-ui/core/colors/green'
import orange from '@material-ui/core/colors/orange'
import deepOrange from '@material-ui/core/colors/deepOrange'
import grey from '@material-ui/core/colors/grey'

import ServiceWorkerWrapper from './ServiceWorkerWrapper'
import Scoreboard from './Scoreboard'
import Handicaps from './Handicaps'
import NewGame from './NewGame'
import PlayerHistory from './PlayerHistory'

const useStyles = makeStyles({
  bottomNavigation: {
    boxShadow: '0 0 10px rgba(0, 0, 0, .2)',
    bottom: 0,
    position: 'fixed',
    width: '100%',
    zIndex: 2,
  },
})

const theme = createMuiTheme({
  palette: {
    primary: {
      main: green.A700,
    },
    success: {
      main: green.A700,
    },
    warning: {
      main: orange.A400,
    },
    error: {
      main: deepOrange.A400,
    },
    text: {
      primary: grey[800]
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
  },
})

function App(props: any) {
  const classes = useStyles()
  const history = useHistory()
  const currentPath = history.location.pathname
  const isAdmin = props?.authData?.username === '16d9efd6-42e3-4f0e-94dd-d75fa21c30c2'

  LogRocket.identify(props?.authData?.attributes?.name)

  return (
    <ThemeProvider theme={theme}>
      <Box>

        <div id="capture">
          <Route exact path="/">
            <Scoreboard />
          </Route>
          <Route path="/handicaps">
            <Handicaps />
          </Route>
          <Route path="/players/:playerId">
            <PlayerHistory />
          </Route>
          <Route path="/new-game">
            {isAdmin ? (
              <NewGame />
            ) : (
                <ComingSoon />
              )}
          </Route>
        </div>

        <BottomNavigation
          className={classes.bottomNavigation}
          value={currentPath}
          onChange={(event, path) => {
            history.push(path)
          }}
          showLabels
        >
          <BottomNavigationAction
            label="Scoreboard"
            value="/"
            icon={<ScoreboardIcon />}
          />
          <BottomNavigationAction
            label="Handicaps"
            value="/handicaps"
            icon={<HandicapIcon />}
          />
          <BottomNavigationAction
            label="New Game"
            value="/new-game"
            icon={<GameIcon />}
          />
        </BottomNavigation>

        <ServiceWorkerWrapper />
      </Box>
    </ThemeProvider>
  )
}

function ComingSoon() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={400}
    >
      <Typography variant="h4">Coming Soon!</Typography>
    </Box>
  )
}

const signUpConfig = {
  includeGreetings: false,
  hideAllDefaults: true,
  signUpFields: [
    {
      displayOrder: 1,
      key: 'username',
      label: 'Email',
      placeholder: 'Email Address',
      required: true,
      type: 'string'
    },
    {
      displayOrder: 2,
      key: 'name',
      label: 'Name',
      placeholder: 'Full Name',
      required: true,
      type: 'string'
    },
    {
      displayOrder: 3,
      key: 'password',
      label: 'Password',
      placeholder: 'Password',
      required: true,
      type: 'password'
    },
  ]
}

export default withAuthenticator(App, false, [], null, null, signUpConfig)
