import React, { useEffect, useState, useRef } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { Auth, Storage } from 'aws-amplify'
import { format } from 'date-fns'
import { orderBy } from 'lodash'
import {
  useHistory,
  useParams,
} from "react-router-dom"
import { Bar } from "react-chartjs-2"

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import Avatar from './components/Avatar'

import ArrowBack from '@material-ui/icons/ArrowBack';

import { ModelSortDirection, Game, Player, UpdatePlayerInput } from './API'
import { gamesByPlayer } from './graphql/queries'
import { updatePlayer } from './graphql/mutations'

const useStyles = makeStyles((theme: Theme) =>
                             createStyles({
                               avatar: {
                                 border: '4px solid #fff',
                                 boxShadow: '0 0 20px rgba(0,0,0,.15)',
                                 width: theme.spacing(16),
                                 height: theme.spacing(16),
                                 marginBottom: theme.spacing(2),
                               },
                             }),
                            )

export default function PlayerHistory() {
  const history = useHistory()
  const { playerId } = useParams()
  const fileInput = useRef(null)
  const classes = useStyles()
  const [user, setUser] = useState()

  const gamesByPlayerQuery = gql`${gamesByPlayer}`
  const { loading, data, error } = useQuery(gamesByPlayerQuery, {
    variables: {
      playerId,
      limit: 20,
      sortDirection: ModelSortDirection.DESC,
    },
    pollInterval: 86400000,
    fetchPolicy: 'cache-and-network',
  })


  const updatePlayerMutation = gql`${updatePlayer}`
  const [updatePlayerFn] = useMutation(updatePlayerMutation)

  useEffect(() => {
    window.scrollTo(0, 0)
    setAuthenticatedUser()
  }, [])

  if (error) {
    return (
      <Snackbar open>
        <Alert severity="error">
          Ah crap, something went wrong.<br />Please let Will know!
        </Alert>
      </Snackbar>
    )
  }

  const games = data?.gamesByPlayer?.items || []
  const player: Player = games[0]?.player
  const playerName = player?.name
  const playerAvatar = player?.avatar
  const playerVersion = player?._version

  // @ts-ignore
  const isCurrentUser = player?.userId === user?.username

  const sortedGames = orderBy(games, ['date'], ['asc'])

  const labels = sortedGames.map((game: Game) => {
    const date = game.date ? new Date(game.date) : new Date()
    const label = format(date, 'dd MMM yyyy')
    return label
  })

  const scores = sortedGames.map((game: Game) => {
    return game.playerStablefordPoints
  })

  const handicaps = sortedGames.map((game: Game) => {
    return game.playerHandicap
  })

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Score',
        type: 'line',
        backgroundColor: 'rgba(255,255,255,0)',
        borderColor: '#00C853',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 5,
        data: scores,
      }, {
        label: 'Handicap',
        type: 'bar',
        data: handicaps,
        backgroundColor: '#ccc',
      }
    ]
  }

  const chartOptions = {
    legend: { display: false },
    scales: {
      xAxes: [
        {
          display: false,
          gridLines: {
            display: false,
          },
        },
      ],
    },
  }

  return (
    <Box>
      <Box
        bgcolor="#f1f1f1"
        p={2.5}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          onClick={history.goBack}
          style={{
            left: 10,
            opacity: 0.6,
            padding: 8,
            position: 'absolute',
            top: 10
          }}
        >
          <ArrowBack />
        </Box>
        {isCurrentUser ? (
          <>
            <input
              id="file"
              type="file"
              onChange={handleAvatarUpload}
              accept="image/jpeg"
              ref={fileInput}
              style={{ visibility: 'hidden', height: 0 }}
            />
            <label
              htmlFor="file"
              style={{
                backgroundColor: '#00C853',
                borderRadius: 4,
                color: '#fff',
                marginBottom: 10,
                padding: 4,
                paddingLeft: 6,
                paddingRight: 6,
                fontSize: '80%',
                fontWeight: 'bold',
              }}
            >
              Change Photo
            </label>
          </>
        ) : null}
        <Avatar
          alt={playerName}
          className={classes.avatar}
          storageKey={playerAvatar}
        />
        <Typography variant="h5" component="h1">
          {loading ? <Skeleton width={100} /> : playerName}
        </Typography>
      </Box>
      <Box p={4}>
        <Bar
          data={chartData}
          options={chartOptions}
        />
      </Box>
      <Box pb={4}>
        <Container maxWidth="sm">
          <Box>
            <List>
              {games.map((game: Game) => {
                if (!game || !game.course) return null

                const id = `${game.competitionId}-${game.date}-${game.playerStablefordPoints}`

                return (
                  <Box
                    key={id}
                    borderBottom="1px solid #eee"
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    marginBottom={2}
                    paddingBottom={2}
                  >
                    <Box flex={1} marginLeft={2}>
                      <Typography
                        color="textPrimary"
                        variant="h6"
                      >
                        {game.course.name}
                      </Typography>
                      {game.date && (
                        <Typography
                          display="inline"
                        >
                          {format(new Date(game.date), 'dd MMM yyyy')}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <Typography
                        color={getScoreColor(game.playerStablefordPoints)}
                        style={{
                          fontSize: '170%',
                          fontStyle: 'italic',
                          fontWeight: 900,
                        }}
                      >
                        {game.playerStablefordPoints}
                      </Typography>
                      <Typography
                        style={{
                          marginTop: -8,
                          fontSize: '60%',
                          fontWeight: 600,
                          letterSpacing: 1,
                          textTransform: 'uppercase',
                        }}
                      >
                        Points
                      </Typography>
                    </Box>
                  </Box>
                )
              })}
            </List>
          </Box>
        </Container>
      </Box>
    </Box>
  )

  async function setAuthenticatedUser() {
    const user = await Auth.currentAuthenticatedUser()
    setUser(user)
  }

  async function handleAvatarUpload(e: React.ChangeEvent<HTMLInputElement>) {
    try {
      const file = e?.target?.files?.[0]

      if (!file || !playerId) return

      console.log('Uploading file...')

      const result: any = await Storage.put(file.name, file)

      console.log('File uploaded!', { result })

      console.log('Updating player...')

      const playerInput: UpdatePlayerInput = {
        id: playerId,
        avatar: result.key,
        _version: playerVersion,
      }

      await updatePlayerFn({
        variables: {
          input: playerInput,
        }
      })

      console.log('Player updated!')
    } catch (err) {
      console.error(err)
    }
  }
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function getScoreColor(score?: number): 'primary' | 'textSecondary' | 'error' {
  if (!score) return 'error'

  if (score > 35) {
    return 'primary'
  } else if (score > 25) {
    return 'textSecondary'
  } else {
    return 'error'
  }
}
