import {
  groupBy,
  orderBy,
  reduce,
  sumBy,
} from 'lodash'

import {
  CourseRating,
  Game,
  Player,
} from '../API'

export interface PlayerScoreboardItem {
  player: Player
  total: number
  topScores: Game[]
}

export function scoreboardAnyHardAndTwoOthers(games: Game[]): PlayerScoreboardItem[] {
  const MAX_SCORES = 3
  const MAX_HARD = 3
  const MAX_OTHER = 2

  const scoresGroupedByPlayer = groupBy(games, 'playerId')
  const scoreboardItems = reduce(scoresGroupedByPlayer, (accum: PlayerScoreboardItem[], games: Game[]) => {
    const gamesSortedByScore = orderBy(games, ['playerStablefordPoints'], ['desc'])
    const topScores = []
    const counts = {
      hard: 0,
      other: 0,
      scores: 0,
    }

    for (const sortedGame of gamesSortedByScore) {
      if (!sortedGame) continue

      const courseRating = sortedGame?.course?.rating
      const isHard = courseRating === CourseRating.HARD
      const isOther = (
        courseRating === CourseRating.MEDIUM ||
          courseRating === CourseRating.EASY
      )

      const countableHardScore = isHard && counts.hard !== MAX_HARD
      const countableOtherScore = isOther && counts.other !== MAX_OTHER

      if (countableHardScore) {
        ++counts.hard
      }

      if (countableOtherScore) {
        ++counts.other
      }

      if (countableOtherScore || countableHardScore) {
        topScores.push(sortedGame)
        ++counts.scores
      }

      const isDone = counts.scores === MAX_SCORES

      if (isDone) break
    }

    const player = games[0] && games[0].player

    if (!player) return accum

    const total = sumBy(topScores, 'playerStablefordPoints')

    accum.unshift({
      player,
      total,
      topScores,
    })

    return accum
  }, [])

  return orderBy(scoreboardItems, ['total'], ['desc'])
}
