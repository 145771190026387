import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { format, isBefore } from 'date-fns'
import {
  useHistory,
} from "react-router-dom"
import html2canvas from 'html2canvas'
import LogRocket from 'logrocket'


import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import CalendarIcon from '@material-ui/icons/EventNoteTwoTone'
import ShareIcon from '@material-ui/icons/Share'
import Avatar from './components/Avatar'

import { Game, ModelSortDirection, Player } from './API'
import { gamesByPlayer, listPlayers } from './graphql/queries'
import { johnEdisburyHandicapCalculator as handicapCalculator } from './lib/handicaps'

const useStyles = makeStyles((theme: Theme) =>
                             createStyles({
                               avatar: {
                                 width: theme.spacing(7),
                                 height: theme.spacing(7),
                               },
                               header: {
                                 position: 'fixed',
                                 top: 0,
                                 width: '100%',
                               },
                               fab: {
                                 position: 'fixed',
                                 bottom: theme.spacing(10),
                                 right: theme.spacing(2),
                               },
                             }),
                            )

export default function Handicaps() {
  const classes = useStyles()
  const query = gql`${listPlayers}`
  const { loading, error, data } = useQuery(query, {
    variables: {
      limit: 999,
    },
    pollInterval: 86400000,
    fetchPolicy: 'cache-and-network',
  })

  if (loading && !data) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={300}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return (
      <Snackbar open>
        <Alert severity="error">
          Ah crap, something went wrong.<br />Please let Will know!
        </Alert>
      </Snackbar>
    )
  }

  const players = data?.listPlayers?.items

  const canShare = !!navigator.share
  // const canShare = true

  return (
    <Box>
      <Box
        bgcolor="primary.main"
        className={classes.header}
        color="white"
        zIndex="appBar"
      >
        <Box p={2.5}>
          <Typography variant="h5" component="h1">
            Handicaps
          </Typography>
          <Typography
            style={{
              fontSize: '90%',
              fontWeight: 600,
              letterSpacing: 2,
              textTransform: 'uppercase',
            }}
          >
            {format(new Date(), 'dd MMM yyyy')}
          </Typography>
        </Box>
        <Box
          bgcolor="#f1f1f1"
          display="flex"
          alignItems="center"
          pl={1}
        >
          <CalendarIcon color="action" fontSize="small" />
          <Typography
            color="textPrimary"
            style={{ marginLeft: 5 }}
            variant="overline"
          >
            Final: 3rd - 5th Dec 2021
          </Typography>
        </Box>
      </Box>
      <Box pt={17} pb={4}>
        <Container maxWidth="sm">
          <Box>
            <List>
              {players.sort((a: Player, b: Player) => (a?.name || '') > (b?.name || '') ? 1 : -1).map((item: Player, index: number) => {
                return <PlayerRow key={item.id} player={item}/>
              })}
            </List>
          </Box>
        </Container>
      </Box>
      {canShare && (
        <div data-html2canvas-ignore>
          <Fab className={classes.fab} color="primary" aria-label="share" onClick={share}>
            <ShareIcon />
          </Fab>
        </div>
      )}
    </Box>
  )

  async function share() {
    // let handicaps = []

    try {
      const base64 = await capture()

      if (!base64) return

      if (!navigator.share) {
        console.error('Share API not available')
        return
      }

      const blob = await (await fetch(base64)).blob();
      const file = new File([blob], 'handicaps.png', { type: blob.type });
      navigator.share({
        title: '⛳ Handicaps',
        // @ts-ignore
        files: [file],
      })

      // for (const key in localStorage) {
      //   if (key.startsWith('handicap-')) {
      //     const json = localStorage.getItem(key)

      //     if (!json) continue

      //     const obj = JSON.parse(json)

      //     if (!obj.handicap || !obj.name) continue

      //     handicaps.push(obj)
      //   }
      // }

      // const sortedHandicaps = orderBy(handicaps, ['name'], ['asc'])

      // let handicapText = '⛳ Handicaps\n\n'

      // sortedHandicaps.forEach((handicap: { name: string, handicap: number }) => {
      //   handicapText += `${handicap.handicap} · ${handicap.name}\n`
      // })

      // console.log(handicapText)

      // if (!navigator.share) {
      //   console.error('Share API not available')
      //   return
      // }

      // await navigator.share({
      //   text: handicapText,
      // })
    } catch (err) {
      console.error('ShareError', {
        err: err.message,
        stack: err.stack,
      })
      LogRocket.captureException(err)
    }
  }
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface PlayerRowProps {
  player: Player
}

function PlayerRow({ player }: PlayerRowProps) {
  const history = useHistory()
  const classes = useStyles()
  const gamesByPlayerQuery = gql`${gamesByPlayer}`
  const { loading, data } = useQuery(gamesByPlayerQuery, {
    variables: {
      playerId: player.id,
      limit: 20,
      sortDirection: ModelSortDirection.DESC,
    },
    pollInterval: 86400000,
    fetchPolicy: 'cache-and-network',
  })
  const games: Game[] = data?.gamesByPlayer?.items || []
  const latestGame = games[0]
  const latestGameDate = latestGame?.date && format(new Date(latestGame.date), 'dd MMM')
  const latestGameText = latestGameDate && `Last Game | ${latestGameDate} - ${latestGame.playerStablefordPoints} pts - ${latestGame.playerHandicap} HCP`

  const isInactivePlayer = !latestGame?.date || isBefore(new Date(latestGame.date), new Date(2020, 0, 1))

  if (!loading && isInactivePlayer) {
    console.debug(`Hiding inactive player ${player.name}`, { latestGame })
    return null
  }

  const handicapInput = {
    games,
  }

  const { handicap } = handicapCalculator(handicapInput)

  localStorage.setItem(`handicap-${player.id}`, JSON.stringify({ handicap, name: player.name }))

  return (
    <Box
      borderBottom="1px solid #eee"
      display="flex"
      alignItems="center"
      flexDirection="row"
      marginBottom={2}
      paddingBottom={2}
      onClick={() => history.push(`/players/${player.id}`)}
    >
      <div data-html2canvas-ignore>
        <Avatar
          alt={player.name}
          className={classes.avatar}
          storageKey={player.avatar}
        />
      </div>
      <Box flex={1} marginLeft={2}>
        <Typography
          color="textPrimary"
          variant="h6"
        >
          {player.name}
        </Typography>
        {latestGameText && (
          <Typography
            color="textSecondary"
            display="inline"
            style={{ fontSize: '75%', letterSpacing: '0.015em', textTransform: 'uppercase' }}
          >
            {latestGameText}
          </Typography>
        )}
      </Box>
      <Box
        color="text.success"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        {(loading && !handicap) ? (
          <CircularProgress size={30} />
        ) : (
          <Typography
            style={{
              fontSize: '170%',
              fontStyle: 'italic',
              fontWeight: 900,
            }}
          >
            {handicap || '-'}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

async function capture() {
  const html = document.querySelector("#capture")

  if (!html) return

  const canvas = await html2canvas(html as HTMLElement)

  // Test
  // document.body.appendChild(canvas)

  const base64 = canvas.toDataURL("image/png")
  return base64
}
