/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompetition = /* GraphQL */ `
  query GetCompetition($id: ID!) {
    getCompetition(id: $id) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCompetitions = /* GraphQL */ `
  query ListCompetitions(
    $filter: ModelCompetitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompetitions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCompetitions = /* GraphQL */ `
  query SyncCompetitions(
    $filter: ModelCompetitionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCompetitions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      name
      par
      rating
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        par
        rating
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCourses = /* GraphQL */ `
  query SyncCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCourses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        par
        rating
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlayer = /* GraphQL */ `
  query GetPlayer($id: ID!) {
    getPlayer(id: $id) {
      id
      avatar
      handicap
      name
      championships
      userId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPlayers = /* GraphQL */ `
  query ListPlayers(
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        avatar
        handicap
        name
        championships
        userId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPlayers = /* GraphQL */ `
  query SyncPlayers(
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPlayers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        avatar
        handicap
        name
        championships
        userId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGame = /* GraphQL */ `
  query GetGame(
    $competitionId: ID!
    $date: String!
    $courseId: ID!
    $playerId: ID!
  ) {
    getGame(
      competitionId: $competitionId
      date: $date
      courseId: $courseId
      playerId: $playerId
    ) {
      competitionId
      courseId
      coursePar
      date
      playerHandicap
      playerId
      playerScore
      playerStablefordPoints
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      competition {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      course {
        id
        name
        par
        rating
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      player {
        id
        avatar
        handicap
        name
        championships
        userId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listGames = /* GraphQL */ `
  query ListGames(
    $competitionId: ID
    $dateCourseIdPlayerId: ModelGamePrimaryCompositeKeyConditionInput
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGames(
      competitionId: $competitionId
      dateCourseIdPlayerId: $dateCourseIdPlayerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        competitionId
        courseId
        coursePar
        date
        playerHandicap
        playerId
        playerScore
        playerStablefordPoints
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        competition {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        course {
          id
          name
          par
          rating
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        player {
          id
          avatar
          handicap
          name
          championships
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const gamesByPlayer = /* GraphQL */ `
  query GamesByPlayer(
    $playerId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gamesByPlayer(
      playerId: $playerId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        competitionId
        courseId
        coursePar
        date
        playerHandicap
        playerId
        playerScore
        playerStablefordPoints
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        competition {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        course {
          id
          name
          par
          rating
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        player {
          id
          avatar
          handicap
          name
          championships
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGames = /* GraphQL */ `
  query SyncGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGames(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        competitionId
        courseId
        coursePar
        date
        playerHandicap
        playerId
        playerScore
        playerStablefordPoints
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        competition {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        course {
          id
          name
          par
          rating
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        player {
          id
          avatar
          handicap
          name
          championships
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
