import React, { useState, useEffect } from 'react'
import { Storage } from 'aws-amplify'
import Avatar from '@material-ui/core/Avatar'

interface Props {
  alt?: string
  className: string
  storageKey?: string
}

export default function PlayerAvatar(props: Props) {
  const { alt, className, storageKey } = props
  const [img, setImg] = useState('https://melbourne-pga.s3-ap-southeast-2.amazonaws.com/avatar-placeholder.jpg')

  useEffect(() => {
    if (!storageKey) return

    getAndSetImage(storageKey)
  }, [storageKey])

  async function getAndSetImage(key: string) {
    const signedUrl = await Storage.get(key) as string

    setImg(signedUrl)
  }

  return (
    <Avatar
      alt={alt}
      className={className}
      src={img}
    />
  )
}
