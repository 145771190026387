import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { format } from 'date-fns'
import {
  useHistory,
} from "react-router-dom"
import html2canvas from 'html2canvas'
import LogRocket from 'logrocket'

import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import List from '@material-ui/core/List'
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import CalendarIcon from '@material-ui/icons/EventNoteTwoTone'
import ShareIcon from '@material-ui/icons/Share'
import Avatar from './components/Avatar'


import yellow from '@material-ui/core/colors/yellow'

import { ModelSortDirection } from './API'
import { listGames } from './graphql/queries'
import { scoreboardAnyHardAndTwoOthers, PlayerScoreboardItem } from './lib/scoreboard'

const useStyles = makeStyles((theme: Theme) =>
                             createStyles({
                               avatar: {
                                 width: theme.spacing(7),
                                 height: theme.spacing(7),
                               },
                               header: {
                                 position: 'fixed',
                                 top: 0,
                                 width: '100%',
                               },
                               fab: {
                                 position: 'fixed',
                                 bottom: theme.spacing(10),
                                 right: theme.spacing(2),
                               },
                             }),
                            )


const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      backgroundColor: yellow[700],
      border: `2px solid white`,
      color: 'white',
      fontSize: '60%',
      fontWeight: 600,
      lineHeight: '105%',
    },
  }),
)(Badge)

export default function Scoreboard() {
  const history = useHistory()
  const classes = useStyles()
  const query = gql`${listGames}`
  const { loading, error, data } = useQuery(query, {
    variables: {
      competitionId: '1ab9dc35-23c3-4aec-be95-485772ef724a',
      limit: 999,
      sortDirection: ModelSortDirection.DESC,
    },
    pollInterval: 86400000,
    fetchPolicy: 'cache-and-network',
  })

  if (loading && !data) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={300}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return (
      <Snackbar open>
        <Alert severity="error">
          Ah crap, something went wrong.<br />Please let Will know!
        </Alert>
      </Snackbar>
    )
  }

  const games = data?.listGames?.items
  const scoreboard = scoreboardAnyHardAndTwoOthers(games)
  const competitionName = games[0]?.competition?.name

  const canShare = !!navigator.share
  // const canShare = true

  return (
    <Box>
      <Box
        bgcolor="primary.main"
        className={classes.header}
        color="white"
        zIndex="appBar"
      >
        <Box p={2.5}>
          <Typography variant="h5" component="h1">
            {competitionName}
          </Typography>
          <Typography
            style={{
              fontSize: '90%',
              fontWeight: 600,
              letterSpacing: 2,
              textTransform: 'uppercase',
            }}
          >
            {format(new Date(), 'dd MMM yyyy')}
          </Typography>
        </Box>
        <Box
          bgcolor="#f1f1f1"
          display="flex"
          alignItems="center"
          pl={1}
        >
          <CalendarIcon color="action" fontSize="small" />
          <Typography
            color="textPrimary"
            style={{ marginLeft: 5 }}
            variant="overline"
          >
            Final: 3rd - 5th Dec 2021
          </Typography>
        </Box>
      </Box>
      <Box pt={17} pb={4}>
        <Container maxWidth="sm">
          <Box>
            <List>
              {scoreboard.map((item: PlayerScoreboardItem, index: number) => {
                return (
                  <Box
                    key={item.player.id}
                    borderBottom="1px solid #eee"
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    marginBottom={2}
                    paddingBottom={2}
                    onClick={() => history.push(`/players/${item.player.id}`)}
                  >
                    <div data-html2canvas-ignore>
                      <StyledBadge
                        overlap="circle"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        badgeContent={item.player.championships}
                      >
                        <Avatar
                          alt={item.player.name}
                          className={classes.avatar}
                          storageKey={item.player.avatar}
                        />
                      </StyledBadge>
                    </div>
                    <Box flex={1} marginLeft={2}>
                      <Typography
                        color="textPrimary"
                        variant="h6"
                      >
                        {index + 1}. {item.player.name} {index === 0 && '🏆'}
                      </Typography>
                      <Typography
                        display="inline"
                      >
                        {item.topScores.map(item => item.playerStablefordPoints).join(' • ')}
                      </Typography>
                    </Box>
                    <Box
                      color={getScoreColor({ playerCount: 1, position: index })}
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <Typography
                        style={{
                          fontSize: '170%',
                          fontStyle: 'italic',
                          fontWeight: 900,
                        }}
                      >
                        {item.total}
                      </Typography>
                      <Typography
                        style={{
                          marginTop: -8,
                          fontSize: '60%',
                          fontWeight: 600,
                          letterSpacing: 1,
                          textTransform: 'uppercase',
                        }}
                      >
                        Points
                      </Typography>
                    </Box>
                  </Box>
                )
              })}
            </List>
          </Box>
        </Container>
      </Box>
      {canShare && (
        <div data-html2canvas-ignore>
          <Fab className={classes.fab} color="primary" aria-label="share" onClick={share}>
            <ShareIcon />
          </Fab>
        </div>
      )}
    </Box>
  )

  async function share() {

    try {
      const base64 = await capture()

      if (!base64) return

      if (!navigator.share) {
        console.error('Share API not available')
        return
      }

      const blob = await (await fetch(base64)).blob();
      const file = new File([blob], 'scoreboard.png', { type: blob.type });
      navigator.share({
        title: '🏆 Scoreboard',
        // @ts-ignore
        files: [file],
      })
    } catch (err) {
      console.error('ShareError', {
        err: err.message,
        stack: err.stack,
      })
      LogRocket.captureException(err)
    }
    // let scoreboardText = '🏆 Scoreboard\n\n'

    // scoreboard.forEach((item: PlayerScoreboardItem) => {
    //   const total = padStart(String(item.total | 0), 3, '·')

    //   scoreboardText += `${total} - ${item.player.name}\n`
    // })

    // console.log(scoreboardText)

    // if (!navigator.share) {
    //   console.error('Share API not available')
    //   return
    // }

    // await navigator.share({
    //   text: scoreboardText,
    // })
  }
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface GetScoreColor {
  playerCount: number
  position: number
}

// TODO auto count
function getScoreColor({ playerCount, position }: GetScoreColor): string {
  if (position < 4) {
    return 'success.main'
  } else if (position < 8 ) {
    return 'text.disabled'
  } else {
    return 'error.main'
  }
}

async function capture() {
  const html = document.querySelector("#capture")

  if (!html) return

  const canvas = await html2canvas(html as HTMLElement)

  // Test
  // document.body.appendChild(canvas)

  const base64 = canvas.toDataURL("image/png")
  return base64
}
